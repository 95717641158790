<template>
    <div class="header">
        <div class="header__inner">
            <router-link to="/" class="header__logo">
                <img 
                    src="../images/logo.svg"
                    class="header__logo-img" 
                    alt="лого">
            </router-link>

            <nav class="navigation header__navigation" :class="{show: isActive}">
                <router-link to="/wedding" class="navigation__link">Свадебная флористика</router-link>
                <router-link to="/catalog" class="navigation__link">Каталог</router-link>
                <router-link to="/delivery" class="navigation__link">Доставка и оплата</router-link>
                <router-link to="/contacts" class="navigation__link">Контакты</router-link>
                <div class="navigation__icons">
                    <button class="navigation__search" @click="showSearchForm"></button>
                    <div class="cart navigation__cart">
                        <div 
                            @click="showCart" 
                            class="cart__inner"
                            :class="{cart_active: isCartOpened}">
                            <img
                            class="cart__ico" 
                            src="../images/icons/basket-ico.svg" 
                            alt="корзина">
                            <span class="cart__quantity">{{CART.length}}</span>
                        </div>
                    </div>
                </div>
            </nav>
            <div v-if="isCartOpened" class="cart__modal">
                <Cart 
                    :cart_data="CART"
                    @openCart="openCart"/> 
            </div>
            <div 
                class="cart__order"
                v-if="isCartOrderOpened">
                    <CartOrder
                        @closeCart="closeCart"/>
            </div>
            <div 
                @click="showCart" 
                class="cart__inner_mobile"
                :class="{cart_active: isCartOpened}">
                <img
                class="cart__ico" 
                src="../images/icons/basket-ico.svg" 
                alt="корзина">
                <span class="cart__quantity">{{CART.length}}</span>
            </div>
            <div 
                class="burger-ico" 
                :class="{menuOpen: isActive}"
                @click="toggleBurger">
                    <span class="burger-ico__item"></span>
            </div>

            <div 
                class="cart-ico-mobile"
                 @click="openCart(true)"
                 :class="{showCartIco:isShowCartIco}">
                 <span class="cart-ico-mobile__quantity">{{CART.length}}</span>
            </div>

        </div>
        <transition name="fade">
            <SearchOverlay 
                v-if="isShow"
                @close="close"/>
        </transition>
    </div>
</template>

<script>
import SearchOverlay from '@/components/Search-overlay'
import Cart from './Cart'
import CartOrder from './Cart-order.vue'
import {mapGetters} from 'vuex'

export default {
    data() {
        return {
            isShow: false,
            isCartOpened: false,
            isCartOrderOpened: false,
            isActive: false,
            isShowCartIco: false
        }
    },
    methods: {
        showSearchForm(){
            this.isShow = true
        },
        close(bool){
            this.isShow = bool
        },
        showCart(){
            this.isCartOpened = !this.isCartOpened
        },
        closeCart(bool){
            this.isCartOrderOpened = bool
            document.body.style.overflowY = 'scroll'
        },
        openCart(bool){
            this.isCartOrderOpened = bool
            document.body.style.overflowY = 'hidden'
        },
        toggleBurger(){
            this.isActive = !this.isActive
        },
        handleScroll(){
            if(window.pageYOffset >= 400 && window.innerWidth <= 600){
                this.isShowCartIco = true
            }else{
                this.isShowCartIco = false
            }
        }
    },
    computed: {
        ...mapGetters([
            'CART'
        ]),
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    components:{
        SearchOverlay,
        Cart,
        CartOrder
    }
}
</script>

<style scoped>
    .header__inner{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
    }
    .header__logo{
        padding: 15px 0;
    }
    .navigation{
        display: flex;
        align-items: center;
    }
    .navigation__link{
        color: #000;
        text-decoration: none;
        margin-left: 40px;
        transition: all linear .2s;
    }
    .navigation__link:hover{
        color: #9F6E73;
    }
    .navigation__link:active{
        color: #000
    }
    .navigation__icons{
        display: flex;
    }
    .navigation__search{
        align-self: center;
    }
    .navigation__search{
        margin-left: 60px;
        margin-right: 35px;
        height: 40px;
        width: 40px;
        background: url(../images/icons/search-ico.svg) no-repeat;
        background-position: center;
        background-size: 27px 27px;
    }
    .navigation__search:hover{
        cursor: pointer;
    }
    .cart{
        position: relative;
        align-items: center;
    }
    .cart__modal{
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;
    }
    .cart__inner{
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 43px 20px;
    }
    .cart__quantity{ 
        margin-left: 15px;
    }
    .cart_active{
        background-color: #EFE7E8;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: all .2s linear;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
    /* бургер */
    .burger-ico{
        display: none;
        align-items: center;
        padding-right: 15px;
        padding-left: 5px;
    }
    .burger-ico__item{
        position: relative;
        width: 40px;
        height: 4px;
        background-color: #000;
    }
    .burger-ico__item::before{
        content: "";
        position: absolute;
        background-color: #000;
        width: 40px;
        height: 4px;
        transform: translate(0, 10px);
        transition: all ease .2s;
    }
    .burger-ico__item::after{
        content: "";
        position: absolute;
        background-color: #000;
        width: 40px;
        height: 4px;
        transform: translate(0, -10px);
        transition: all ease .2s;
    }
    .menuOpen span{
        transform: rotate(45deg);
    }
    .menuOpen span::before{
        transform: rotate(-90deg);
    }
    .menuOpen span::after{
        transform: translate(0);
    }
    .cart__inner_mobile{
        display: none;
    }
    .cart-ico-mobile{
        display: none;
        position: fixed;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, .75);
        background-image: url(../images/icons/basket-ico.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 55%;
        top: 30px;
        right: 30px;
        z-index: 50;
    }
    .cart-ico-mobile__quantity{
        position: absolute;
        right: 1px;
        bottom: 50%;
        margin-bottom: -9px;
    }
    .showCartIco{
        display: block;
    }
    @media (max-width: 992px){
        .header__logo-img{
            max-width: 190px;
        }
        .navigation__link{
            margin-left: 30px;
        }
    }
    @media (max-width: 768px){
        .header__logo-img{
            max-width: 160px;
        }
        .header__logo{
            padding: 0;
        }
        .cart__inner{
            padding: 25px 20px;
        }
        .header__inner{
            font-size: 14px;
        }
        .navigation__link{
            margin-left: 20px;
        }
        .navigation__search{
            margin-left: 30px;
            margin-right: 15px;
        }
    }
    @media (max-width: 600px) {
        .header__inner{
            position: relative;
            justify-content: initial;
        }
        .header__logo{
            padding: 20px 0;
        }
        .header__navigation{
            position: absolute;
            display: none;
            width: 100vw;
            margin-left: -15px;
            margin-right: -15px;
            flex-direction: column;
            padding: 10px;
            top: 100%;
            right: 0;
            z-index: 100;
            background-color: #FAF2E4;
        }
        .cart__modal{
            width: 100vw;
            margin-left: -15px;
            margin-right: -15px;
            box-shadow: 0 15px 25px #000;
        }
        .navigation__link{
            margin: 0;
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px dashed #000;
            width: 100%;
            text-align: center;
        }
        .cart__inner{
            display: none;
        }
        .cart__inner_mobile{
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: 25px;
        }
        .cart__quantity{
            font-size: 15px;
        }
        .navigation__search{
            display: none;
        }

        .burger-ico{
            display: flex;
        }
        .show{
            display: flex;
        }
    }
</style>