import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/components/pages/Main-page'


const routes = [
    {
        component: MainPage,
        name: 'main',
        path: '/'
    },
    {
        path: '/delivery',
        name: 'Delivery-page',
        component: ()=> import('@/components/pages/Delivery-page')
    },
    {
        path: '/contacts',
        name: 'Contact-page',
        component: ()=> import('@/components/pages/Contact-page')
    },
    {
        path: '/catalog',
        name: 'Catalog-page',
        component: ()=> import('@/components/pages/Catalog-page')
    },
    {
        path: '/login',
        name: 'Login-page',
        component: ()=> import('@/components/admin/Login-page')
    },
    {
        path: '/admin',
        name: 'Admin-page',
        component: ()=> import('@/components/admin/Admin-page')
    },
    {
        path: '/orders',
        name: 'Orders-page',
        component: ()=> import('@/components/admin/Orders-page')
    },
    {
        path: '/statistics',
        name: 'Statistics-page',
        component: ()=> import('@/components/admin/Statistics-page')
    },
    {
        path: '/admproduct',
        name: 'admproduct',
        component:()=> import('@/components/admin/Admin-product') 
    },
    {
        path: '/admin-main',
        name: 'Admin-main-page',
        component: ()=> import('@/components/admin/Admin-main-page')
    },
    {
        path: '/collection-item',
        name: 'collection-item',
        component: ()=> import('@/components/admin/Collection-item')
    },
    {
        path: '/success',
        name: 'success',
        component: ()=> import('@/components/Success')
    },
    {
        path: '/operator',
        name: 'operator',
        component: ()=> import('@/components/Operator')
    },
    {
        path: '/confidential',
        name: 'confidential',
        component: ()=> import('@/components/pages/Confidential-page')
    },
    {
        path: '/wedding',
        name: 'wedding',
        component: ()=> import('@/components/pages/wedding/Wedding-page')
    },
    {
        path: '/admin-floral',
        name: 'admin-floral',
        component: ()=> import('@/components/admin/Admin-floral')
    },
    {
        path: '/admin-floral-edit',
        name: 'admin-floral-edit',
        component: ()=> import('@/components/admin/Admin-floral-edit')
    },
    {
        path: '/admin-promo',
        name: 'admin-promo',
        component: ()=> import('@/components/admin/Promo-page')
    },
    {
        path: '/admin-delivery',
        name: 'admin-delivery',
        component: ()=> import('@/components/admin/Admin-delivery')
    },
    {
        path: '/admin-popular',
        name: 'admin-popular',
        component: ()=> import('@/components/admin/Admin-popular')
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
        return {el: to.hash, behavior: 'smooth'}
    } else {
        return { x: 0, y: 0 }
    }
},
})

export default router
