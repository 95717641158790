<template>
    <div class="cart-item">
        <div class="cart-item__wrapper">
            <div class="cart-item__inner">
                <img :src="'https://dff24.com:3000/' + cart_item_data.url" class="cart-item__img">
                <div class="cart-item__info">
                    <div class="cart-item__text"> 
                        <p class="cart-item__name">{{ cart_item_data.name }}</p>
                        <span class="cart-item__price">{{ cart_item_data.price }} р</span>
                    </div>

                    <div class="controls cart-item__controls">
                        <button class="controls__decrease controls__btn" @click="quantityDecrease">
                        </button>
                        
                        <output class="controls__output">{{ cart_item_data.quantity }}</output>

                        <button class="controls__increase controls__btn" @click="quantityIncrease">
                        </button>
                    </div>
                    <button @click="deleteFromCart" class="cart-item__delete"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        cart_item_data: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    mounted(){

    },
    methods: {
        deleteFromCart(){
            this.$emit('deleteFromCart')
        },
        quantityIncrease(){
            this.$emit('increase')
        },
        quantityDecrease(){
            this.$emit('decrease')
        }
    }
}
</script>

<style scoped>
    .cart-item__inner{
        margin-top: 30px;
        padding-bottom: 25px;
        border-bottom: 1px dashed #000;
        display: flex;
        justify-content: space-between;
    }
    .cart-item__info{
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        padding: 0 30px;
    }
    .cart-item__img{
        width: 100%;
        max-width: 105px;
        max-height: 105px;
    }
    .cart-item__name{
        margin-bottom: 30px;
        font-size: 16px;
    }
    .cart-item__price{
        font-size: 24px;
        margin-bottom: 25px;
    }
    .cart-item__controls{
        display: flex;
        align-items: center;
        align-self: flex-end;
        justify-content: space-between;
        border: 1px solid #000;
        max-width: 145px;
        height: 45px;
        width: 100%;
    }
    .controls__btn{
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 44px;
        transition: all linear .2s;
    }
    .controls__btn:hover{
        cursor: pointer;
    }
    .controls__decrease:hover{
        background-color: #000;
    }
    .controls__decrease::before{
        position: absolute;
        content: "";
        height: 1px;
        width: 18px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        background-color: #000;
    }
    .controls__decrease:hover::before{
        background-color: #fff;
    }
    .controls__increase:hover{
        background-color: #000;
    }
    .controls__increase::before{
        position: absolute;
        content: "";
        height: 1px;
        width: 18px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        background-color: #000;
    }
    .controls__increase::after{
        position: absolute;
        content: "";
        height: 1px;
        width: 18px;
        top: 50%;
        left: 50%;
        margin-left: -9px;
        transform: rotate(90deg);
        background-color: #000;
    }
    .controls__increase:hover::before,
    .controls__increase:hover::after{
        background-color: #fff;
    }
    .controls__output{
        display: inline-block;
        font-size: 24px;
        margin: 0 10px;
    }
    .cart-item__delete{
        position: absolute;
        top: 0;
        right: 0;
        height: 25px;
        width: 25px;
        background: none;
        padding: 0;
        border: 0;
        transition: all linear .1s;
    }
    .cart-item__delete::before{
        position: absolute;
        content: "";
        height: 1px;
        width: 25px;
        background-color: #000;
        top: 50%;
        left: 0;
        transform: rotate(45deg);
    }
    .cart-item__delete::after{
        position: absolute;
        content: "";
        height: 1px;
        width: 25px;
        background-color: #000;
        top: 50%;
        left: 0;
        transform: rotate(-45deg);
    }
    .cart-item__delete:hover{
        background-color: #d8d8d8;
        cursor: pointer;
    }
    @media (max-width: 600px) {
        .cart-item__info{
            flex-direction: column;
        }
        .cart-item__price{
            display: inline-block;
            margin-bottom: 40px;
        }
        .cart-item__controls{
            align-self: flex-start;
        }
    }
</style>