<template>
    <div class="cart">
        <div class="cart__wrapper">
            <h2 class="cart__heading">Ваши товары:</h2>
            <CartItem
                    v-for="(item, index) in cart_data"
                    :key="item.id"
                    :cart_item_data="item"
                    @deleteFromCart="deleteFromCart(index)"
                    @increase="increase(index)"
                    @decrease="decrease(index)"
                    />
            <div class="cart-item__total">
                <p>Сумма заказа: <span class="cart-item__total-price">{{ cartTotalCost }}</span> р.</p>
            </div>
            <button class="cart-item__btn" @click="openCart">Оформить заказ</button>
        </div>
    </div>
</template>

<script>
import CartItem from './Cart-item'
import {mapActions} from 'vuex'

export default {
    components: {
        CartItem
    },
    props: {
        cart_data: {
            type: Array,
            default(){
                return []
            }
        }
    },
    computed: {
        cartTotalCost(){
            let result = [0]
            for (let item of this.cart_data){
                result.push(item.price * item.quantity)
            }
            result = result.reduce(function (sum, el){
                return sum + el;
            })
                return result;
        }
    },
    methods: {
        ...mapActions([
            'DELETE_FROM_CART',
            'INCREASE_QTY',
            'DECREASE_QTY'
        ]),
        deleteFromCart(index){
            this.DELETE_FROM_CART(index)
        },
        increase(index){
            this.INCREASE_QTY(index)
        },
        decrease(index){
            this.DECREASE_QTY(index)
        },
        openCart(){
            this.$emit('openCart', true)
        }
    }
}
</script>

<style scoped>
    .cart__wrapper{
        display: flex;
        flex-direction: column;
        min-width: 535px;
        padding: 30px 30px 30px 30px;
        border: 1px dashed #000;
        background-color: #FAF2E4;
    }
    .cart__heading{
        font-size: 24px;
        margin: 0;
    }
    .cart-item__total{
        font-size: 26px;
        margin-top: 25px;
        margin-bottom: 30px;
    }
    .cart-item__total-price{
        margin-left: 20px;
    }
    .cart-item__btn{
        align-self: center;
        text-align: center;
        color: #fff;
        background-color: #000;
        padding: 17px 65px;
        text-transform: uppercase;
        font-size: 18px;
    }
    .cart-item__btn:hover{
        cursor: pointer;
    }
    @media (max-width: 992px) {
        .cart__wrapper{
            width: 350px;
        }
    }
    @media (max-width: 600px){
        .cart__wrapper{
            min-width: auto;
            width: 100%;
            border: none;
            border-bottom: 1px dashed #000;
        }
    } 
</style>