<template>
    <div class="main-page">
        <div class="main-page__admin-link" v-if="isAdmin">
            <router-link to="/admin">Войти в админ панель</router-link>
        </div>
        <div class="wrapper">
            <Header/>
        </div>
        <div class="main-page__banner">
            <Banner/>
        </div>
        <div class="wrapper">
            <div class="main-page__popular">
                <PopularProducts/>
            </div>
            <div class="main-page__collection">
                <Collection/>
            </div>
            <div class="main-page__btn">
                <CatalogBtn/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Banner from '@/components/Banner'
import PopularProducts from '@/components/Popular-products'
import Collection from '@/components/Collection'
import Footer from '@/components/Footer'
import CatalogBtn from '@/components/Catalog-btn'

export default {
    components:{
        Header,
        Banner,
        PopularProducts,
        Collection,
        CatalogBtn,
        Footer
    },
    data() {
        return {
            isAdmin: false
        }
    },
    mounted() {
        let role = localStorage.getItem('role')
        if (role == 'admin') {
            this.isAdmin = true
        }
    },
}
</script>

<style>
    .wrapper{
        padding: 0 60px;
    }
    .main-page__banner,
    .main-page__collection{
        margin-bottom: 60px;
    }
    .main-page__popular{
        margin-bottom: 130px;
    }
    .main-page__btn{
        margin-bottom: 100px;
    }
    .main-page__admin-link{
        padding: 20px;
        text-align: center;
        border-bottom: 1px dashed #000;
    }
    @media (max-width: 992px){
        .wrapper{
            padding: 0 30px;
        }
    }
    @media (max-width: 768px){
        .wrapper{
            padding: 0 15px;
        }
    }
    @media (max-width: 600px){
        .main-page__popular{
            margin-bottom: 60px;
        }
    }
</style>