import { createStore } from 'vuex'
import axios from "axios"

export default createStore({
    state: {
        products: [],
        popularProducts: [],
        additionalProducts: [],
        collection: [],
        isSort: false,
        categories: [],
        currentProduct: {},
        selectedCategogy: 'Все',
        mainProductPhoto: '',
        searchValue: '',
        sortDirection: '',
        page: 0,
        discount: 0,
        cardsPerPage: 12,
        totalPages: 0,
        cart: []
    },
    getters: {
        PRODUCTS(state){
            return state.products;
        },
        ADDITIONAL_PRODUCTS(state){
            return state.additionalProducts;
        },
        POPULAR_PRODUCTS(state){
            return state.popularProducts
        },
        TOTAL_PAGES(state){
            return state.totalPages
        },
        CURRENT_PAGE(state){
            return state.page
        },
        CATEGORIES(state){
            return state.categories
        },
        SELECTED_CATEGORY(state){
            return state.selectedCategogy
        },
        SELECTED_PRODUCT(state){
            return state.currentProduct
        },
        CART(state){
            return state.cart
        },
        COLLECTION(state){
            return state.collection
        },
        MAIN_PHOTO(state){
            return state.mainProductPhoto
        },
        DISCOUNT(state){
            return state.discount
        }
    },
    mutations: {
        SET_PRODUCTS_TO_STATE: (state, products)=>{
            state.products = products
        },
        SET_POPULAR_PRODUCTS: (state, products)=>{
            state.popularProducts = products
        },
        SET_MORE_PRODUCTS: (state, products)=>{
            state.products = state.products.concat(products.rows)
        },
        SET_TOTAL_PAGES: (state, value)=>{
            state.totalPages = Math.ceil(value / state.cardsPerPage)
        },
        SET_CATEGORIES: (state, categories)=>{
            state.categories = categories
        },
        SET_SELECTED_CATEGORY: (state, category)=>{
            state.selectedCategogy = category
        },
        SET_ONE_PRODUCT: (state, data)=>{
            state.currentProduct = data
            state.mainProductPhoto = data.url
        },
        SET_MAIN_PHOTO: (state, url)=>{
            state.mainProductPhoto = url
        },
        SET_COLLECTION: (state, collection)=>{
            state.collection = collection
        },
        SET_CART: (state, product)=>{
            if(state.cart.length){
                let isProductExist = false;
                state.cart.map(function(item){
                    if(item.id === product.id){
                        isProductExist = true;
                        item.quantity++
                    }
                })
                if(!isProductExist){
                    state.cart.push(product)
                }
            }else{
                state.cart.push(product)
            }
        },
        REMOVE_FROM_CART: (state, index)=>{
            state.cart.splice(index, 1)
        },
        INCREASE: (state, index)=>{
            state.cart[index].quantity++
        },
        DECREASE: (state, index)=>{
            if (state.cart[index].quantity > 1){
                state.cart[index].quantity--
            }
        },
        REMOVE_PRODUCTS: (state)=>{
            state.products = []
        },
        SET_SORT_DIRECTION:(state, direction)=>{
            state.sortDirection = direction
        },
        SET_SEARCH_VALUE: (state, value) => {
            state.searchValue = value
        },
        REMOVE_SEARCH_VALUE: (state)=>{
            state.searchValue = ''
        },
        SET_DEFAULT_PAGE_NUMBER: (state, value)=>{
            state.page = value
        },
        SET_ADDITIONAL_PRODUCTS:(state, prods)=>{
            state.additionalProducts = prods
        },
        SET_DISCOUNT: (state, discount) =>{
            state.discount = discount
        },
    },
    actions: {
        IS_DISCOUNT({commit}, discount){
            commit('SET_DISCOUNT', discount)
        },
        GET_COLLECTION({commit}){
            return axios('https://dff24.com:3000/api/collection/',{
                method: "GET"
            }).then((collection)=>{
                commit('SET_COLLECTION', collection.data)
            })
        },
        GET_ADDITIONAL_PRODUCTS({commit}){
            return axios('https://dff24.com:3000/api/goods/additional',{
                method: "GET"
            }).then((res)=>{
                commit('SET_ADDITIONAL_PRODUCTS', res.data)
            })
        },
        GET_MORE_PRODUCTS({commit}){
            if(this.state.searchValue != ''){
                return axios({
                    url: 'https://dff24.com:3000/api/goods/search',
                    method: "GET",
                    params:{
                        searchVal: this.state.searchValue,
                        dir: this.state.sortDirection,
                        page: ++this.state.page
                    }

                }).then((products)=>{
                    commit('SET_MORE_PRODUCTS', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }

            if(this.state.selectedCategogy !=='Все'){
                return axios({
                    url: 'https://dff24.com:3000/api/goods/sort',
                    method: "GET",
                    params:{
                        category: this.state.selectedCategogy,
                        dir: this.state.sortDirection,
                        page: ++this.state.page
                    }

                }).then((products)=>{
                    commit('SET_MORE_PRODUCTS', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }
            return axios({
                url: 'https://dff24.com:3000/api/goods/flowers/all',
                method: "GET",
                params:{
                    page: ++this.state.page,
                    dir: this.state.sortDirection
                }
            }).then((products)=>{
                commit('SET_MORE_PRODUCTS', products.data);
                commit('SET_TOTAL_PAGES', products.data.count);
            })
        },
        GET_POPULAR_PRODUCTS({commit}){
            return axios({
                url: 'https://dff24.com:3000/api/goods/flowers/popular',
                method: "GET"
            }).then((products)=>{
                commit('SET_POPULAR_PRODUCTS', products.data);
            })
        },
        GET_CATEGORIES({commit}){
            return axios('https://dff24.com:3000/api/category/all',
            {
                method: "GET",
            }).then((categories)=>{
                commit('SET_CATEGORIES', categories.data);
            })
        },
        SELECT_CATEGORY({commit}, value){
            commit('SET_SELECTED_CATEGORY', value)
        },
        GET_ONE_PRODUCT({commit}, data){
            commit('SET_ONE_PRODUCT', data)
        },
        ADD_TO_CART({commit}, product){
            commit('SET_CART', product);
        },
        INCREASE_QTY({commit}, index){
            commit('INCREASE', index)
        },
        DECREASE_QTY({commit}, index){
            commit('DECREASE', index)
        },
        DELETE_FROM_CART({commit}, index){
            commit('REMOVE_FROM_CART', index)
        },
        GET_SORT_DIRECTION({commit}, direction){
            commit('SET_SORT_DIRECTION', direction)
        },
        GET_SEARCH_VALUE({commit}, value){
            commit('SET_SEARCH_VALUE', value)
        },
        RESET_SEARCH_VALUE({commit}){
            commit('REMOVE_SEARCH_VALUE')
        },
        SELECT_MAIN_PHOTO({commit}, url){
            commit('SET_MAIN_PHOTO', url)
        },
        RESET_PAGE_NUMBER({commit}){
            if(this.state.page >= 1){
                commit('REMOVE_PRODUCTS')
            }
            commit('SET_DEFAULT_PAGE_NUMBER', 0)
        }
    }
})
