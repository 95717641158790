<template>
    <div class="catalog-item">
        <img class="catalog-item__img" :src="'https://dff24.com:3000/' + product_data.url" @click="productClick(product_data)">
        <p class="catalog-item__name">{{product_data.name}}</p>
        <div 
            class="catalog-item__footer"
            :class="{additional_footer: product_data.isAdditional}">
            <span 
                class="catalog-item__price">{{product_data.price}} р</span>
            <button 
                class="catalog-item__btn" 
                @click="addToCart"
                v-if="curRouter != 'Admin-page'">в корзину</button>
        </div> 
    </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    props: {
        product_data: {
            type: Object,
            default(){
                return{

                }
            }
        }
    },
    computed: {
        curRouter(){
            return this.$route.name
        }
    },
    methods: {
        ...mapActions([
            'GET_ONE_PRODUCT',
            'ADD_TO_CART'
        ]),
        addToCart(){
            this.ADD_TO_CART(this.product_data)
            this.$emit('addToCart', null)
        },
        productClick(data){
            this.GET_ONE_PRODUCT(data)
            this.$emit('showProduct', true)
        }
    },
}
</script>

<style scoped>
    .catalog-item{
        display: flex;
        flex-direction: column;
    }
    .catalog-item__img{
        width: 100%;
        aspect-ratio: 1 / 1;
        margin-bottom: 15px;
        transition: all linear .2s;
    }
    .catalog-item__img:hover{
        transform: scale(.95);
        cursor: pointer;
    }
    .catalog-item__name{
        font-size: 18px;
        margin-bottom: 20px;
    }
    .catalog-item__price{
        font-size: 24px;
        margin-right: 15px;
    }
    .catalog-item__footer{
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .catalog-item__btn{
        font-size: 18px;
        border-bottom: 1px dashed #000;
        margin-right: 25px;
        text-transform: uppercase;
    }
    .catalog-item__btn:hover{
        border-bottom: 1px solid #000;
        cursor: pointer;
    }
    .outOfStock{
        opacity: .5;
        pointer-events: none;
    }
    @media (max-width: 992px){
        .catalog-item__name{
            font-size: 16px;
        }
        .catalog-item__price{
            font-size: 20px;
        }
        .catalog-item__btn{
            text-transform: none;
        }
    }
    @media (max-width: 768px) {
        .catalog-item__price{
            font-size: 18px;
        }
        .catalog-item__btn{
            font-size: 16px;
            margin-right: 0;
        }
    }
    @media (max-width: 600px){
        .catalog-item__img{
            margin-bottom: 20px;
        }
        .catalog-item__price{
            font-size: 24px;
        }
        .catalog-item__btn{
            font-size: 18px;
            text-transform: uppercase;
            min-width: 102px;
        }
        .additional_footer{
            flex-direction: column;
            align-items: flex-start;
            font-size: 14px;
        }
        .additional_footer .catalog-item__btn{
            margin-top: 10px;
        }
    }
</style>